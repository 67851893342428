import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { NextSeo } from 'next-seo';
import Heading from '@/components/frontend/Heading';

const ErrorPage: FC<{
  title?: string;
  subHeading?: string;
  status?: string | null;
}> = ({ children, subHeading, title, status = '404' }) => (
  <>
    {status && <NextSeo title={status} />}
    <Box maxW="container.xl" m="5% auto 3rem" px="5" textAlign="center">
      {status ? (
        <Heading variant="h1" as={title ? 'p' : 'h1'} color="secondaryColor">
          {status}
        </Heading>
      ) : null}
      {subHeading && (
        <Heading variant="h1" transform="scale(.55)" as="h2">
          {subHeading}
        </Heading>
      )}
      {title && (
        <Heading variant="h1" transform="scale(.75)" as="h1">
          {title}
        </Heading>
      )}
      {children}
    </Box>
  </>
);

export default ErrorPage;
